class ActivityReportsRoot {
  $scope:            any;
  $location:         any;
  errFactory:        any;
  loaderFactory:     any;
  assignmentFactory: any;

  isOpened:          boolean;
  constructor($scope, $location, ErrFactory, LoaderFactory, AssignmentFactory) {
    Object.defineProperties(this, {
      $scope:            { value: $scope            },
      $location:         { value: $location         },
      errFactory:        { value: ErrFactory        },
      loaderFactory:     { value: LoaderFactory     },
      assignmentFactory: { value: AssignmentFactory }
    });
  }

  changeLocationWithEBS(event, to): Promise<any> {
    event.stopPropagation();
    this.loaderFactory.show();
    return this.assignmentFactory.getOwn()
    .then((jobs) => {
      if (jobs.length) return this.$location.path(to);
      else return Promise.reject(new this.errFactory.NoEBS)
    })
    .catch((err) => {
      if (err instanceof new this.errFactory) err.notify();
      else console.error(err);
    })
    .then(() => this.loaderFactory.hide());
  }

}

window.app.component('arRoot', {
  template: require('scripts/components/time-trackings/ar-root/ar-root.html'),
  controller: ['$scope', '$location', 'ErrFactory', 'LoaderFactory', 'AssignmentFactory', ActivityReportsRoot]
});
